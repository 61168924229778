module.exports = {
  project_name: "ulsemo-dev", // TODO get rid of
  email_name: "info", // TODO get rid of
  project_domain: "ulsemo.com", // TODO get rid of
  password: "Ulsemo-ReischigIndustries-0", // Change also in other.js constants
  project_id: "ReischiIndustries-Ulsemo-0",
  email_service: "gmail",
  id_of_project: 1,
  is_dev_environment: true,
  project_server_location: "europe-west1",
  default_shopify_API_version: "2020-01",
  styles: {
    colors: {
      item_name_color: "#fff",
      background_heavy: "#1b1b1b",
    }
  },
  firebase_config: {
    apiKey: "AIzaSyAfPuj7wfHfKyTrKd52SxIw-XPOCPB1qvY",
    authDomain: "ulsemo-dev.firebaseapp.com",
    databaseURL: "https://ulsemo-dev.firebaseio.com",
    projectId: "ulsemo-dev",
    storageBucket: "ulsemo-dev.appspot.com",
    messagingSenderId: "474207412838",
    appId: "1:474207412838:web:3530c1de148ef6e085c3a7"
  },
  google_analytics: {
    email: "firebase-adminsdk-a25s9@template-page.iam.gserviceaccount.com", // TODO change to fetch
    key: "a80f19c305dd4d9daf77c8dc43b7f6bcca6ae35b", // TODO change to fetch
    site_verification: "googlee7469d47b7d070b6.html" // TODO change to fetch
  },
  paypal_urls: {
    sandbox: (version = "v2") => `https://api.sandbox.paypal.com/${version}`,
    production: (version = "v2") => `https://api.paypal.com/${version}`,
    connection_check: cliend_id => `https://www.paypal.com/sdk/js?client-id=${cliend_id}`
  },
  openexchangerates: {
    API_key: "88f6bfd8bbdd434e96d5c22ca912908b"
  },
  other: {
    thumb_image_sizes: [312, 540],
    thumb_content_size: [500]
  },
  stripe_config: {
    API_token: "sk_test_51HcrOxHLd0x1guwyNiVx9BqmxQjzZbFlKvyIUEnvdzsQLuOOjaZcxNvf0sL6pv8mUmLPseYUxIiA3H0MoY8aItHQ00Fc5st3of",
    price_IDs: {
      base: "price_1K1COeHLd0x1guwyiW0G46aN",
      metered: "price_1K1COeHLd0x1guwyVqwGysXz"
    },
    webhook_secret: {
      local: "whsec_4jBSxVv8jPYmOnK0Nu5PQvN1RZXSGVNH",
      prod: "whsec_5qyckAoTzJmQPOCivojcZW7uERiCJhaj"
    }
  }
}
