<template>
  <section class="contact-page" :class="{ 'contact-page--no-info': !show_address }">
    <h1>{{ translations.contact_us.title }}</h1>
    <div class="contact-page__form">
      <label for="fullname">{{ translations.contact_us.fullname_label }}</label>
      <input
        v-model="name"
        type="text" 
        name="fullname"
        :placeholder="translations.contact_us.fullname_placeholder"
        @click="validation_success = true"
      >
      <label for="email">{{ translations.contact_us.email_label }}</label>
      <input
        v-model="email"
        type="email"
        name="email"
        :placeholder="translations.contact_us.email_placeholder"
        @click="validation_success = true"
      >
      <label for="message">{{ translations.contact_us.message_label }}</label>
      <textarea
        v-model="message"
        name="message"
        cols="30"
        rows="15"
        @click="validation_success = true"
      />
      <div class="contact-page__send">
        <div
          v-if="!validation_success || user_message_success || user_message_fail"
          class="contact-page__message"
          :class="{
            'contact-page__message--success': user_message_success
          }"
        >
          {{
            user_message_success ?
              translations.contact_us.message_success : 
              user_message_fail ? translations.contact_us.message_fail : translations.contact_us.validation_fail 
          }}
        </div>
        <button class="button" @click="send_message">{{ translations.contact_us.send_button }}</button>
      </div>
    </div>

    <div v-if="show_address" class="contact-page__info">
      <h3>{{ translations.contact_us.store_info_title }}</h3>
      <div>
        <div v-if="shipping_options.project_address.company">{{ shipping_options.project_address.company }}</div>
        <div>{{ shipping_options.project_address.street1 }}{{ shipping_options.project_address.street2 ? `, ${shipping_options.project_address.street2}` : "" }}</div>
        <div>{{ shipping_options.project_address.zip }}, {{
          shipping_options.project_address.state ? `${shipping_options.project_address.state}, ` : ""
        }}{{ get_country_by_code(shipping_options.project_address.country).name }}</div>
        <div v-if="shipping_options.project_address.phone">📞 {{ shipping_options.project_address.phone }}</div>
        <br>
        <div v-if="shipping_options.project_address.businessID">{{ translations.contact_us.businessID }} {{ shipping_options.project_address.businessID }}</div>
        <div v-if="shipping_options.project_address.vatID">{{ translations.contact_us.vatID }} {{ shipping_options.project_address.vatID }}</div>
        <div v-if="shipping_options.project_address.bank_account">{{ translations.contact_us.bank_account }} {{ shipping_options.project_address.bank_account }}</div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"
import { project_name, project_server_location } from "../../../../../functions/config"
import { CLIENT_STORE } from "../../constants/other"
import get_country_by_code from "../../../Shared/methods/get_country_by_code"

export default {
  data() {
    return {
      name: "",
      email: "",
      message: "",
      user_message_success: false,
      validation_success: true,
      user_message_fail: false
    }
  },
  computed: {
    ...mapState(CLIENT_STORE, [
      "translations",
      "shipping_options"
    ]),
    show_address() {
      return this.shipping_options?.project_address?.show_in_contact_page
    }
  },
  methods: {
    get_country_by_code,
    clear_data() {
      this.name = ""
      this.email = ""
      this.message = ""
    },
    /**
     * 
     */
    are_all_fields_filled_out() {
      return this.name !== "" &&
        this.email !== "" &&
        this.message !== ""
    },
    /**
     * 
     */
    send_message() {
      if(this.are_all_fields_filled_out()) {
        const message_data = {
          full_name: this.name,
          email: this.email,
          message: this.message
        }

        axios.post(`https://${project_server_location}-${project_name}.cloudfunctions.net/user_message_mailer`, message_data)
          .then(res => {
            // FB event
            if (window.fbq) fbq('track', 'Contact')
            if(!res) {
              this.user_message_fail = true
              this.user_message_success = false
              setTimeout(() => this.user_message_fail = false, 3000)
            }
            else {
              this.user_message_fail = false
              this.user_message_success = true
              setTimeout(() => this.user_message_success = false, 3000)
            }
            this.clear_data()

          })
          .catch(err => {
            this.user_message_fail = true
            this.user_message_success = false
          })
      }
      else {
        this.user_message_success = false
        this.user_message_fail = false
        this.validation_success = false
      }
    }
  }
}
</script>

<style lang="scss">
@use "../../../../styles/_global-constants" as *;

.contact-page {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "title title"
    "form info";
  column-gap: 40px;
  row-gap: 40px;
  margin-bottom: 100px;

  &--no-info {
    grid-template-areas:
      "title title"
      "form form";
  }

  @media (max-width: $tablet) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "title"
      "form"
      "info";
    column-gap: 0;
    row-gap: 30px;
    margin-bottom: 60px;
  }

  &__info {
    margin: 0 auto;
    grid-area: info;
    width: 100%;
    max-width: 500px;

    h3 {
      text-align: center;
      font-size: 25px;
      margin: 0 0 30px;
    }
  }

  &__form {
    grid-area: form;
  }

  h1 {
    grid-area: title;
    color: var(--background_heavy);
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    margin-bottom: 0;
  }

  input {
    display: block;
    width: 100%;
    max-width: 500px;
    padding: 5px 10px;
    margin: 0 auto 20px;
    box-sizing: border-box;
    line-height: 40px;
    font-size: 20px;
    text-align: left;
    border: 1px solid var(--background_heavy);
  }

  label {
    display: block;
    width: 100%;
    max-width: 500px;
    margin: 10px auto;
  }

  textarea {
    display: block;
    width: 100%;
    max-width: 500px;
    padding: 5px 10px;
    margin: auto;
    font-size: 15px;
    box-sizing: border-box;
    border: 1px solid var(--background_heavy);
    resize: none;
  }

  &__send {
    position: relative;
    display: block;
    width: 100%;
    max-width: 500px;
    margin: auto;

    

    button {
      display: block;
      width: 100%;
      margin: 20px 0;
      font-size: 18px;
      font-weight: 100;
    }
  }

  &__message {
    position: absolute;
    left: 0;
    bottom: 55px;
    width: 100%;
    line-height: 25px;
    padding: 5px;
    font-size: 15px;
    text-align: center;
    color: $pure-white;
    background-color: $alert-color;
    box-sizing: border-box;

    &::after {
      position: absolute;
      top: calc(100% - 5px);
      left: calc(50% - 5px);
      width: 10px;
      height: 10px;
      background-color: $alert-color;
      content: "";
      transform: rotate(45deg);
    }

    &--success {
      background-color: $green-color;

      &::after {
        background-color: $green-color;
      }
    }
  }

  ::placeholder {
    text-align: left;
  }
}
</style>
